<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Form from './form';
import enableForm from './form/enableForm.vue';

export default {
  name: 'fee_rule_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    enableForm,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          type: val.code,
        };
        this.openFull();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          type: val.code,
          row,
        };
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          row,
          type: val.code,
        };
        this.openFull();
      } else if (val.code === 'enable') {
        if (this.selection.length > 0) {
          this.formConfig = {
            selection: this.selection,
          };
          this.formName = 'enableForm';
          this.modalConfig.title = '启用';
          this.modalConfig.width = '400px';
          this.modalConfig.height = '200px';
          this.openModal();
          // this.$confirm('确认要启用吗?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          //   .then(() => {
          //     request.post('/tpm/tpmCostRules/enable', row.map((v) => v.id)).then((res) => {
          //       if (res.success) {
          //         this.$message({
          //           type: 'success',
          //           message: '操作成功',
          //         });
          //         this.getList();
          //       }
          //     });
          //   });
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
    },
  },
  created() {
    this.getConfigList('fee_rule_list');
  },
};
</script>
