import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import ProductList from './product-list.vue';
import CustomerList from './customer-list.vue';
import PositionList from './position-list.vue';
import FeeSuject from './fee-suject.vue';
import OrgList from './org-list.vue';
import OrgList1 from './org-list1.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('CustomerList', CustomerList);
formCreate.component('PositionList', PositionList);
formCreate.component('FeeSuject', FeeSuject);
formCreate.component('OrgList', OrgList);
formCreate.component('OrgList1', OrgList1);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'add_fee_rule',
      costRulesTierList: [],
      buttons: {
        submit: this.formConfig.type !== 'view',
      },
    };
  },

  methods: {

    // 获取
    getCostRulesTier() {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'cost_rules_tier' }).then((res) => {
        if (res.success) {
          this.costRulesTierList = res.result || [];
        }
      });
    },
    // 设置规则
    setRule(item) {
      const v = item;
      if (v.field === 'productName') { // 选择产品
        v.props = {
          ...v.props,
          params: {
            functionCode: 'standard_product_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'customerName') { // 选择客户
        v.props = {
          ...v.props,
          params: {
            functionCode: 'fee_cutmoer_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'positionName') { // 选择职位
        v.props = {
          ...v.props,
          params: {
            functionCode: 'fee_position_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'organizationName') { // 选择组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'fee_org_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 1 },
        };
      } else if (v.field === 'organizations') { // 多选组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'fee_org_list_1',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            // let orgNames = '';
            const organizations = [];
            e.forEach((m) => {
              const obj = m;
              // orgNames += `${obj.orgName},`;
              organizations.push({
                organizationName: obj.orgName,
                orgName: obj.orgName,
                orgCode: obj.orgCode,
                organizationCode: obj.orgCode,
              });
            });
            this.setValue({
              // orgName: organizations || '',
              organizations: organizations || [],
            });
          },
        };
      }
      if (v.field === 'itemList') {
        v.props = {
          ...v.props,
          saveRef: this.saveSujectRef,
          formConfig: this.formConfig,
        };
      }
      return item;
    },
    // 根据费用预算类型 --- 获取预算科目
    getBudgetData(val) {
      const itemList = this.getRule('itemList');
      if (!val) {
        itemList.props = {
          ...itemList.props,
          budgetAccount: [],
        };
        return;
      }
      request.post('/tpm/tpmBudgetSubjectsExt/list', { subjectsTypeCode: val }).then((res) => {
        if (res.success) {
          itemList.props = {
            ...itemList.props,
            budgetAccount: res.result,
          };
        }
      });
    },
    // 控制字段显隐
    controleField(e) {
      const { type } = this.formConfig;
      if (type === 'add') {
        if (e === 'one') { // 产品/客户/市场活动
          this.hiddenFields(true, ['organizationName', 'organizations', 'customerType']);
          this.setValue({
            organizationName: null,
            organizationCode: null,
            customerType: null,
            // orgName: null,
            organizations: null,
          });
          this.hiddenFields(false, ['customerName']);
        } else if (e === 'two' || e === 'five') { // 产品/组织/市场活动 ||  产品/组织/市场运营
          this.hiddenFields(true, ['customerName', 'customerType']);
          this.setValue({
            customerName: null,
            customerCode: null,
            customerType: null,
          });
          this.hiddenFields(false, ['organizations']);
        } else if (e === 'three') { // 产品/客户/市场运营
          this.hiddenFields(true, ['organizationName', 'organizations', 'customerType']);
          this.setValue({
            organizationName: null,
            organizationCode: null,
            customerType: null,
            // orgName: null,
            organizations: null,
          });
          this.hiddenFields(false, ['customerName']);
        } else if (e === 'four') { // 产品/组织/客户类型/市场运营
          this.hiddenFields(true, ['customerName']);
          this.setValue({
            customerName: null,
            customerCode: null,
          });
          this.hiddenFields(false, ['organizations', 'customerType']);
        } else if (e === 'six') { // 产品/市场活动
          this.hiddenFields(true, ['customerName', 'customerType', 'organizationName', 'organizations']);
          this.setValue({
            organizationName: null,
            organizationCode: null,
            customerName: null,
            customerCode: null,
            customerType: null,
            // orgName: null,
            organizations: null,
          });
        }
      } else if (e === 'one') { // 产品/客户/市场活动
        this.hiddenFields(true, ['organizationName', 'organizations', 'customerType']);
        this.setValue({
          organizationName: null,
          organizationCode: null,
          customerType: null,
          // orgName: null,
          organizations: null,
        });
        this.hiddenFields(false, ['customerName']);
      } else if (e === 'two' || e === 'five') { // 产品/组织/市场活动 ||  产品/组织/市场运营
        this.hiddenFields(true, ['customerName', 'customerType']);
        this.setValue({
          customerName: null,
          customerCode: null,
          customerType: null,
        });
        this.hiddenFields(false, ['organizationName']);
      } else if (e === 'three') { // 产品/客户/市场运营
        this.hiddenFields(true, ['organizationName', 'organizations', 'customerType']);
        this.setValue({
          organizationName: null,
          organizationCode: null,
          customerType: null,
          // orgName: null,
          organizations: null,
        });
        this.hiddenFields(false, ['customerName']);
      } else if (e === 'four') { // 产品/组织/客户类型/市场运营
        this.hiddenFields(true, ['customerName']);
        this.setValue({
          customerName: null,
          customerCode: null,
        });
        this.hiddenFields(false, ['organizationName', 'customerType']);
      } else if (e === 'six') { // 产品/市场活动
        this.hiddenFields(true, ['customerName', 'customerType', 'organizationName', 'organizations']);
        this.setValue({
          organizationName: null,
          organizationCode: null,
          customerName: null,
          customerCode: null,
          customerType: null,
          // orgName: null,
          organizations: null,
        });
      }
    },

    // 完成渲染
    formComplete() {
      this.getCostRulesTier();
      const { type } = this.formConfig;
      if (type === 'edit' || type === 'view') {
        this.hiddenFields(true, ['organizations', 'organizations', 'saleChannels']);
        request.get('/tpm/tpmCostRules/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.getBudgetData(res.result.budgetType);
            this.controleField(res.result.costRulesTier);
            this.setValue(res.result);
          }

          if (type === 'edit') { // 为编辑的时候
            const nowDate = _.toDateString(new Date(), 'yyyy-MM-dd');
            const { beginDate, endDate } = res.result;

            if (new Date(beginDate) <= new Date(nowDate)) { // 开始时间小于等于当前系统时间
              // 比较结束时间和当前时间关系
              // 结束时间大于当前系统时间，则仅允许修改结束时间
              if (new Date(endDate) > new Date(nowDate)) {
                this.disabled(true, ['costRulesTier', 'productName', 'budgetType', 'organizationName', 'customerName', 'customerType', 'positionName', 'beginDate']);
                const itemList = this.getRule('itemList');
                itemList.props = {
                  ...itemList.props,
                  isDisable: false,
                  formConfig: this.formConfig,
                };
              } else { // 结束时间也小于当前时间，所有的都不允许修改
                const itemList = this.getRule('itemList');
                console.log('itemList', itemList);
                itemList.props = {
                  ...itemList.props,
                  formConfig: this.formConfig,
                  isDisable: false,
                };

                this.disabled(true, ['costRulesTier', 'productName', 'budgetType', 'organizationName', 'customerName', 'customerType', 'positionName', 'beginDate', 'endDate']);
              }
            } else {
              // 开始时间大于当前系统时间，允许修改所有内容，且开始时间只能修改为当前系统时间

              const beginDates = this.getRule('beginDate');
              beginDates.props = {
                ...beginDates.props,
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() < new Date() - 8.64e7; // 如果没有后面的-8.64e7就是不可以选择今天的
                  },
                },
              };
            }
          }
        });
      } else if (type === 'add') {
        this.hiddenFields(true, ['organizationCode', 'organizationName', 'saleChannel']);
      }

      const costRulesTier = this.getRule('costRulesTier');
      costRulesTier.on.change = (e) => {
        if (e) {
          this.costRulesTierList.forEach((c, cIndex) => {
            if (c.dictCode === e) {
              // 需求： 根据预算层级控制预算类型
              const temp = c.fee_budget_type.split(',');
              const budgetList = [];
              if (temp.length === 1) {
                this.setValue({
                  budgetType: c.fee_budget_type,
                });
                this.getBudgetData(c.fee_budget_type);
                this.disabled(true, ['budgetType']);
              }
              if (temp.length > 1) {
                request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'subjects_type' }).then((res) => {
                  if (res.success) {
                    for (const item of temp) {
                      res.result.forEach((tRes) => {
                        if (item === tRes.dictCode) {
                          budgetList.push({
                            label: tRes.dictValue,
                            value: tRes.dictCode,
                          });
                        }
                      });
                    }
                    this.setValue({
                      budgetType: null,
                    });
                    const budgetType = this.getRule('budgetType');
                    budgetType.options = budgetList;
                    this.disabled(false, ['budgetType']);
                  }
                });
              }
            }
          });
          this.controleField(e);
        } else {
          this.setValue({
            organizationName: null,
            organizationCode: null,
            customerName: null,
            customerCode: null,
            customerType: null,
            budgetType: null,
          });
          this.getBudgetData(null);
        }
      };

      const budgetType = this.getRule('budgetType');
      budgetType.on = {
        change: (e) => {
          this.getBudgetData(e);
          if (this.sujectRef) {
            this.sujectRef.clearBudgetSubject();
          }
        },

      };
      const productName = this.getRule('productName');
      productName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productName: e[0].materialName,
            productCode: e[0].materialCode,
            productSeriesDesc: e[0].productLevelName,
            productSeriesCode: e[0].productLevelCode,

          });
        } else {
          this.setValue({
            productName: null,
            productCode: null,
            productSeriesDesc: null,
            productSeriesCode: null,
          });
        }
      };
      // 客户
      const customerName = this.getRule('customerName');
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
          });
        }
      };
      // 职位
      const positionName = this.getRule('positionName');
      positionName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            positionName: e[0].positionName,
            positionCode: e[0].positionCode,
          });
        } else {
          this.setValue({
            positionName: null,
            positionCode: null,
          });
        }
      };
      // 处理组织
      const organizationName = this.getRule('organizationName');
      organizationName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            organizationName: e[0].orgName,
            organizationCode: e[0].orgCode,
          });
        } else {
          this.setValue({
            organizationName: null,
            organizationCode: null,
          });
        }
      };
      // 处理规则参数
      const itemList = this.getRule('itemList');
      if (itemList) {
        itemList.props = {
          ...itemList.props,
          formConfig: this.formConfig,
        };
      }
    },

    saveSujectRef(ref) {
      this.sujectRef = ref;
    },
    /* eslint-disable */
     //返回数组元素是否出现重复项（等于0：没有，大于0：有）
     checkArrayElement(array){
        array.sort();  //数组排序
        var reNum = 0;  //返回结果
        //遍历整个数组对象
        for(var i=0;i<array.length;i++){
             //跳过最后一个元素的比较
            if (i + 1 == array.length) {
                continue;
            }
            //判断相邻的元素是否相同
            if (array[i] == array[i + 1]) {
                reNum += 1;
            }
        }
        return reNum;
    },
    // 提交
    submit() {
      const isRuleRight = this.fApi.method('itemList', 'submitInfoObj')();
      const formData = this.getFormData(); // 获取表单的值
      console.log(formData,222);
      if (isRuleRight && formData) {
        const ruleData = this.fApi.method('itemList', 'backData')();
        const tempArr = [];
        for (const item of ruleData) {
          tempArr.push(item.budgetSubjectCode);
        }
        if (this.checkArrayElement(tempArr)>0) {
          this.$message.warning('存在重复的预算科目，请修改');
          return;
        }

        if(new Date(formData.beginDate)>new Date(formData.endDate)){
          this.$message.error('开始时间不能大于结束时间');
          return;
        }


        formData.itemList = ruleData;
        // 默认新增
        let url = '/tpm/tpmCostRules/add';
        // 有id  就是编辑
        if (this.formConfig.row && this.formConfig.row.id) {
          url = '/tpm/tpmCostRules/update';
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
