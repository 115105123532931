<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Form from './form';

export default {
  name: 'standard_protect_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          type: val.code,
          id: null,
        };
        this.openFull();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          ...row,
          type: val.code,
          id: row.id,
        };
        this.openFull();
      }
    },
    // 删除操作
    // deleteClick({ row }) {
    //   const ids = row ? [row.id] : this.selection;

    //   const title = '此操作将永久删除此数据, 是否继续?';
    //   const url = '/mdm/mdmRoleController/delete';
    //   const msg = '删除';

    //   const data = {
    //     params: ids,
    //     url,
    //     title,
    //     msg,
    //   };
    //   this.messageBox(data);
    // },
  },
  created() {
    this.getConfigList('standard_protect_list');
  },
};
</script>
